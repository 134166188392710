<template>
	<footer class="footer">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="footer__copyright">
						© {{currentYear}} Liga za duševné zdravie
						<img src="~/assets/images/logo.svg" alt="Liga za duševné zdravie"  class="header__logo"/>
					</div>
				</div>
			</div>
			<div class="row">
        		<div class="col-md-4 col-sm-6 col-12" v-for="menuItem in menuData">
          			<ul class="footer__links">
            			<li v-for="childMenu in menuItem.child_items">
              				<nuxt-link :to="childMenu.url" tag="a">{{childMenu.post_title}}</nuxt-link>
            			</li>
          			</ul>
        		</div>

<!--				<div class="col-md-3 col-sm-6 col-12">-->
<!--					<ul class="footer__links">-->
<!--						<li><nuxt-link to="/potrebujem-pomoc" tag="a">Potrebujem pomoc</nuxt-link></li>-->
<!--						<li><nuxt-link to="/o-nas/kto-sme" tag="a">O nás</nuxt-link></li>-->
<!--						<li><nuxt-link to="/vsetky-vysledky/kurzy" tag="a">Online kurzy</nuxt-link></li>-->
<!--						<li><nuxt-link to="/" tag="a">Koalícia Ligy</nuxt-link></li>-->
<!--						<li><nuxt-link to="/" tag="a">Projekty</nuxt-link></li>-->
<!--						<li><nuxt-link to="/o-nas/kontakty" tag="a">Kontakty</nuxt-link></li>-->
<!--						<li><nuxt-link to="/podporte-nas" tag="a">Podporte nás</nuxt-link></li>-->
<!--						<li><nuxt-link to="/archiv" tag="a">Archív</nuxt-link></li>-->
<!--					</ul>-->
<!--				</div>-->
<!--				<div class="col-md-3 col-sm-6 col-12">-->
<!--					<ul class="footer__links">-->
<!--						<li><nuxt-link to="/e-mailova-poradna-linky-dovery-nezabudka" tag="a">E-mailová poradňa Linky dôvery Nezábudka</nuxt-link></li>-->
<!--						<li><nuxt-link to="/2percenta-z-dani" tag="a">2% z daní</nuxt-link></li>-->
<!--						<li><nuxt-link to="/otazky-a-odpovede" tag="a">Otázky a odpovede</nuxt-link></li>-->
<!--						<li><nuxt-link to="https://dninezabudiek.sk/" tag="a">Zbierka Dni nezábudiek</nuxt-link></li>-->
<!--						<li><nuxt-link to="/o-nas/pre-media" tag="a">Pre média</nuxt-link></li>-->
<!--						<li><nuxt-link to="/o-nas/eticke-kodexy" tag="a">Naše etické kódexy</nuxt-link></li>-->
<!--						<li><nuxt-link to="/" tag="a">Duša a film</nuxt-link></li>-->
<!--						<li><nuxt-link to="http://galerianezabudka.dusevnezdravie.sk/" tag="a">Galéria Nezábudka</nuxt-link></li>-->
<!--					</ul>-->
<!--				</div>-->

				<div class="col-md-4 col-sm-6 col-12 text-right-center">
          <div v-for="(item, index) in contactWidget" :key="index" v-html="renderWidget(item)"></div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<ul class="footer__links mt-72">
						<li><nuxt-link to="/ochrana-osobnych-udajov" tag="a" class="text-underline">Ochrana osobných údajov</nuxt-link></li>
					</ul>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
	export default {
		name: 'Footer',
    data() {
      return {
        menuData: [],
        contactWidget: [],
        currentYear: new Date().getFullYear(),
      }
    },
    methods: {
      renderWidget(widget) {
        // You'll need to implement rendering logic for each widget type
        switch (widget.widget_type) {
          case 'text':
            return `<div>${widget.text}</div>`;
          case 'block':
            return widget.content;
            // Add cases for other widget types as needed
          default:
            return null;
        }
      }
    },
    async mounted() {
      this.menuData = await fetchWP('/backend/wp-json/menus/v1/menus/paticka');
      this.contactWidget = await fetchWP('/backend/wp-json/custom/v1/widgets/footer-contact');
      this.menuData = this.menuData.items;
      // fill up to three
      // for (let i = this.menuData.length + 1; i <= 3; i++) {
      //   this.menuData.push({child_items:[]});
      // }
    },
	}
</script>
